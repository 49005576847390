.formContainer{
    max-width: 100%;
    height: 100%;
    align-self: center;
    justify-self: center;
  }
  
  .Label{
    width: 100%;
    align-self: flex-start;
  }

  .inputTxt{
    align-self: flex-end;
  }

  
  .formRow{
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 10px;
  }

  .btnRegistrar{
    margin-top: 20px;
    background-color: white;
    height: 40px;
    border-radius: 15px;
    
  }

  .ddPais{
    align-self: flex-end;
    width: 160px;
  }

  .SuccessReg{
    color: green;
    font-weight: bold;
  }

  .FailReg{
    color: red;
    font-weight: bold;
  }
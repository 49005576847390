.App {
  text-align: center;
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.App-logo {
  height: 80vmin;
  pointer-events: none;
}


.App-header {
  background-color: #D5DB83;
  min-height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-weight: bolder;
}

.App-link {
  color: #61dafb;
}

.Footer {
  background-color: #E4B131;
  width: 100%;
  height: 80px;
}
.sectionHead{
  font-size: large;
  font-weight: bold;  
  margin-top: 25px;
}

.Body {
  position: relative;
  width: 100%;
  flex-direction: row;
  height: 100vh;
}

.seccionForm{
  height: 200vh;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 40px;
}

.banner{
  width: '60%';
  display:'inline-block';
  margin-top:'40px';
}

@media (max-width: 750px) {
 
  .banner{
    width: '60%';
    display:'inline-block';
    margin-top:'40px';
  }

  .App-logo {
    height: 50vmin;
    pointer-events: none;
  }

  }